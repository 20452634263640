import "./App.css";

import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import ContentContainer from "./components/ContentContainer";
import SuccessModal from "./components/Modal";
import Wheel from "./components/Wheel";
/*import bg from "./img/BG.png";*/
import bg from "./img/bg_new.png";
import playedImage from "./img/confused.svg";
import logo from "./img/logo.svg";
import axios from "axios";
import environment from "./environment";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(55,52,52,0.7)",
    zIndex: 99,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    border: "none",
    maxWidth: "80vw",
    minWidth: "60vw",
    minHeight: "70vh",
  },
};

Modal.setAppElement("#root");

function App() {
  const [spin, setSpin] = useState(0);

  const [prize, setPrize] = useState<any>({});

  const [userEmail, setUserEmail] = useState("");
  const [result, setResult] = useState(false);
  const [storeUrl, setStoreUrl] = useState("");

  // Set local storage after game was played
  useEffect(() => {
    if (Object.keys(prize).length) {
      setTimeout(() => {
        setShowModal(true);
      }, 1500);
      localStorage.setItem(
        "played",
        JSON.stringify({
          played: "true",
          timestamp: new Date(),
        })
      );
    }
  }, [prize]);

  // Get from localstorage, if game was played already today, not to show
  useEffect(() => {
    const playHistory = localStorage.getItem("played");
    if (playHistory) {
      const parsedHistory = JSON.parse(playHistory);
      if (
        moment(new Date()).format("DD.MM.YYYY") ===
        moment(parsedHistory.timestamp).format("DD.MM.YYYY")
      ) {
        setUserPlayed(true);
      }
    }
  }, []);

  const [showModal, setShowModal] = useState(false);

  const [userPlayed, setUserPlayed] = useState(false);

  const [isLastDay, setIsLastDay] = useState(false);

  const [responseErrorMessage, setResponseErrorMessage] = useState("");

  const [changeTitle, setChangeTitle] = useState(false);

  /*useEffect(() => {
    if (
      Object.keys(data)[Object.keys(data).length - 1] ===
      moment(new Date()).format("DD.MM.YYYY")
    ) {
      setIsLastDay(true);
    }
  }, []);*/

  const [availableDeals, setAvailableDeals] = useState(false);

  const [wheelDatesFinished, setWheelDatesFinished] = useState(false);

  useEffect(() => {
    const getDeal = async () => {
      const deals = await axios.get(`${environment.baseUrl}benefit/deals`);
	  if (deals?.data) {
		  setWheelDatesFinished(deals.data);
	  }
      /*const dealsAvailable = await axios.get(`${environment.baseUrl}benefit/deals/indexByDate?date=${new Date().toISOString()}`);*/
      /*if (deals && deals.data && deals.data.count) {
        /!*if (deals.data.count > 0 && dealsAvailable.data === true) {
          if (deals.data.count < 101 && moment(new Date()).format("DD.MM.YYYY") === "24.11.2021") {
            setChangeTitle(true);
          }
          setAvailableDeals(true);
        }*!/
      }*/
    };
    getDeal();
  }, []);

  console.log("root", result);

  return (
    <div>
      {wheelDatesFinished && Object.keys(wheelDatesFinished).length
        ? userPlayed ? (
          <div
            className="page"
            style={{ backgroundColor: "#28305F", minHeight: "100vh" }}
          >
            <header className="header">
              <img src={logo} alt="corplife_logo" />
            </header>
            <div
              style={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="played_box">
                <h2>Ups!</h2>
                <p>Es tut uns leid, aber du kannst nur einmal pro Tag spielen.</p>
                {isLastDay ? (
                  <p>
                    Wir wünschen dir viel Glück bei unseren weiteren Gewinnspielen.
                  </p>
                ) : (
                  <p>Komm morgen wieder, um das Glücksrad erneut zu drehen.</p>
                )}
                <img src={playedImage} alt="played" />
              </div>
            </div>
          </div>
        ) : (
          <div className="page" style={{ backgroundImage: `url('${bg}')` }}>
            <Modal isOpen={showModal} style={customStyles}>
              <SuccessModal
				  prize={prize}
				  responseErrorMessage={responseErrorMessage}
				  storeUrl={storeUrl}
			  />
            </Modal>
            <header className="header">
              <img src={logo} alt="corplife_logo" />
            </header>
            {/*SCREEN*/}
            <main className="screen">
              <div className="wheel_container">
				  <Wheel
					  spin={spin}
					  setPrize={setPrize}
					  prize={prize}
					  email={userEmail}
					  setResponseErrorMessage={(error:string) => setResponseErrorMessage(error)}
					  availableDeals={availableDeals}
					  result={result}
				  />
              </div>
              <ContentContainer
                setSpin={() => setSpin(spin + 1)}
                spin={spin}
                setUserEmail={(email:string) => setUserEmail(email)}
                changeTitle={changeTitle}
                setUserPlayed={() => setUserPlayed(true)}
                setResult={(result) => setResult(result)}
                setStoreUrl={(url: string) => setStoreUrl(url)}
              />
            </main>
          </div>
        )
        : <div
          className="page"
          style={{
            backgroundColor: "#28305F",
            minHeight: "100vh"
          }}
        >
          <header className="header">
            <img src={logo} alt="corplife_logo" />
          </header>
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="played_box">
              <h2>OOPS!</h2>
              <p>Es tut uns leid! Sie können einmal pro Tag spielen!</p>
              {isLastDay ? (
                <p>
                  Es gibt keine Spiele mehr
                </p>
              ) : (
                <p>Bitte fiolgen Sie unsere Newsletter!</p>
              )}
              <img src={playedImage} alt="played" />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
