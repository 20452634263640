import React, {useEffect, useRef, useState} from "react";
import "./ContentContainer.css";
import bigText from "../img/bigwin.gif";
import bigWinImage from "../img/BIG_WIN.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import axios from "axios";
import environment from "../environment";

interface IProps {
	setSpin?: () => void;
	mobile?: boolean;
	spin: number;
	setUserEmail: (email:string) => void;
	changeTitle: boolean;
	setUserPlayed: () => void;
	setResult: (result: boolean) => void;
	setStoreUrl: (url: string) => void;
}

export default function ContentContainer({setSpin, mobile, spin, setUserPlayed, setUserEmail, setResult, setStoreUrl}:IProps) {
  const ref = useRef<any>();
  const [termsAccept, setTermsAccept] = useState(false);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("E-Mail sollte gültig sein")
        .required("E-Mail ist erforderlich")
    }),
    // handle form submitting
    onSubmit: async () => {
      const play = await axios.post(
		  `${environment.baseUrl}benefit/deals`,
        {
          email: formik.values.email
        }
	  )
		  .then((res: any) => {
			  return res.data;
		  })
		  .catch((e: any) => {
			  console.error(e);
			  if (e.response.status === 422) {
				  return "user_not_exists";
			  }
			  return undefined;
		  });

	  if (play) {

		  if (play.storeUrl) {
			  setStoreUrl(play.storeUrl);
		  }

		  if (play === "user_not_exists") {
			  formik.setFieldError("email", "Dieser Benutzer existiert nicht");
		  } else {
			  setResult(play.winner);
			  if (setSpin) {
				  setSpin();
				  setUserEmail(formik.values.email);
			  }
		  }
	  } else {
		  setUserPlayed();
	  }
    },
  });

  const todayDate = moment(new Date()).format("DD.MM.YYYY");

  /*const dataCopy:any = {...data};*/

  return(
    <form onSubmit={formik.handleSubmit} className="content_container">
      <div className="content_box">
        <img src={bigWinImage} className="big_text" alt="big_win"/>
        {/*<h2>{changeTitle
          ? "Gutschein-Code von Egoist im Wert von € 5,-"
          : dataCopy[todayDate].data[0].text}</h2>*/}
        <p>Drehe das Glücksrad und gewinne!</p>
        <div style={{position: "relative", width: "100%"}}>
          <input
            type="text"
            placeholder="E-Mail-Adresse"
            name="email"
            value={formik.values.email}
            disabled={spin > 0}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.email &&
					formik.touched.email && (
            <p className="input_warning">
              {formik.errors.email}
            </p>
          )}
        </div>
        <label
          style={mobile ? {marginTop: "0px"} : {}}
          className="container"
        >
					Ich habe die
          <a rel="noreferrer" href="https://my.corplife.at/wheel_of_fortune_terms_23" target="_blank"> Teilnahmebedingungen</a> gelesen
					und verstanden
          <input type="checkbox" onChange={() => setTermsAccept(!termsAccept)}/>
          <span className="checkmark"></span>
        </label>
        {mobile
          ? null
          : <button
            type={"submit"}
            disabled={spin > 0 || !termsAccept || !(formik.isValid && formik.dirty)}
          >
            Jetzt drehen
          </button>
        }
      </div>
    </form>
  );
}
