import React, {useEffect, useState} from "react";
import "./Modal.css";
import lostImage from "../img/lost.svg";
import winImage from "../img/win.svg";
import moment from "moment";

interface IProps {
	prize: any;
	responseErrorMessage: string;
	storeUrl: string;
}

export default function SuccessModal({prize, responseErrorMessage, storeUrl}:IProps) {

  const [isLastDay, setIsLastDay] = useState(false);

  /*useEffect(() => {
    if (Object.keys(data)[Object.keys(data).length - 1]
		=== moment(new Date()).format("DD.MM.YYYY")) {
      setIsLastDay(true);
    }
  }, []);*/

  const returnErrorData = () => {
    switch (responseErrorMessage) {
    case "No deal availabe":
      return {
        title: "Es gibt leider keine deals füe Heute",
        text: "Bitte versuchen Sie es morgen noch einmal",
      };
    case "No more vouchers":
      return {
        title: "Ups!",
        text: "Es tut uns leid, aber du kannst nur einmal pro Tag spielen. Komm morgen wieder, um das Glücksrad erneut zu drehen.",
      };
    case "Email already registered for this deal!":
      return {
        title: "E-Mail bereits für dieses Angebot registriert!",
        text: "Bitte versuchen Sie es morgen noch einmal",
      };
    default:
      return {
        title: "Es gibt leider keine deals füe Heute",
        text: "Bitte versuchen Sie es morgen noch einmal",
      };
    }
  };

  console.log(prize);

  return(
    <div className="modal">
      {prize.title !== "lose"
        ? <>
          <h2>
            {
              responseErrorMessage.length
                ? returnErrorData().title
                : "Herzlichen Glückwunsch!"
            }
          </h2>
          <p>
            {
              responseErrorMessage.length
                ? returnErrorData().text
                : "Du hast gewonnen."
            }
          </p>
          {responseErrorMessage.length
            ? null
            : <p>Bitte überprüfe deine E-Mail, um deinen Gewinn zu erhalten.</p>
          }
          <img src={responseErrorMessage.length  ? lostImage : winImage} alt="win"/>
			  <button
				  disabled={false}
				  style={{marginTop: "30px"}}
				  onClick={() => {
					  location.href = storeUrl;
				  }}
			  >
				  Zurück zu deiner Benefits Plattform
			  </button>
        </>
        : <>
          <h2>Es tut uns leid!</h2>
          {isLastDay
            ? <p>
              Du hast heute leider nicht gewonnen. Wir wünschen dir viel Glück bei unseren weiteren Gewinnspielen.</p>
            : <p>
              Du hast heute nicht gewonnen. Komm morgen wieder und versuche dein Glück erneut!</p>
          }
          <img src={lostImage} alt="lost"/>
			  <button
				  disabled={false}
				  style={{marginTop: "30px"}}
				  onClick={() => {
					  location.href = storeUrl;
				  }}
			  >
				  Zurück zu deiner Benefits Plattform
			  </button>
        </>
      }
    </div>
  );
}
