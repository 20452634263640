import "./Wheel.css";
import environment from "../environment";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";

import { useWindowSize } from "../hooks/windowSize";
import spinBg from "../img/border.svg";
import centerDot from "../img/center.svg";
import pinImage from "../img/PIN.svg";
import podium from "../img/podium.png";
import podiumShadow from "../img/podium_shadow.svg";
import wheelShadow from "../img/shadow_wheel_inside@2x.png";
import axios from "axios";

interface IProps {
  spin: number;
  setPrize: (prize: any) => void;
  prize: any;
  email: string;
  setResponseErrorMessage: (error:string) => void;
  availableDeals: boolean;
  result: boolean;
}

const lights = [
  { top: "0.7vw", left: "23vw" },
  { top: "2.3vw", left: "27vw" },
  { top: "5.5vw", left: "31vw" },
  { top: "9vw", left: "33.5vw" },
  { top: "13.4vw", left: "35.3vw" },
  { top: "17.5vw", left: "35.8vw" },
  { top: "22.1vw", left: "35.2vw" },
  { top: "26.3vw", left: "33.4vw" },
  { top: "30.3vw", left: "30.3vw" },
  { top: "32.7vw", left: "27vw" },
  { top: "34.5vw", left: "22.8vw" },
  { top: "35.1vw", left: "18.1vw" },
  { top: "34.4vw", left: "13.5vw" },
  { top: "32.6vw", left: "9.4vw" },
  { top: "29.7vw", left: "5.6vw" },
  { top: "26.4vw", left: "3.1vw" },
  { top: "22vw", left: "1.3vw" },
  { top: "17.6vw", left: "0.8vw" },
  { top: "12.5vw", left: "1.5vw" },
  { top: "8.8vw", left: "3vw" },
  { top: "5.2vw", left: "5.8vw" },
  { top: "2.4vw", left: "9.3vw" },
  { top: "0.6vw", left: "13.9vw" },
];

const lightsPad = [
  { top: "1.1vw", left: "38.2vw" },
  { top: "3.9vw", left: "45.1vw" },
  { top: "9.2vw", left: "52vw" },
  { top: "14.6vw", left: "55.9vw" },
  { top: "22.6vw", left: "59vw" },
  { top: "29.5vw", left: "59.7vw" },
  { top: "37vw", left: "58.8vw" },
  { top: "44vw", left: "56vw" },
  { top: "50vw", left: "51vw" },
  { top: "54.5vw", left: "45.2vw" },
  { top: "57.4vw", left: "38.5vw" },
  { top: "58.6vw", left: "30.5vw" },
  { top: "57.6vw", left: "23vw" },
  { top: "54.8vw", left: "16vw" },
  { top: "50vw", left: "9.5vw" },
  { top: "43.8vw", left: "5vw" },
  { top: "36.5vw", left: "2vw" },
  { top: "29.5vw", left: "1.1vw" },
  { top: "20.8vw", left: "2.3vw" },
  { top: "14.5vw", left: "5.2vw" },
  { top: "8.5vw", left: "9.8vw" },
  { top: "4vw", left: "15.7vw" },
  { top: "1vw", left: "23vw" },
];

const lightsPhone = [
  { top: "1.4vw", left: "55.8vw" },
  { top: "5.2vw", left: "65.2vw" },
  { top: "13vw", left: "75vw" },
  { top: "21.5vw", left: "81.5vw" },
  { top: "32vw", left: "85.3vw" },
  { top: "42vw", left: "86.5vw" },
  { top: "53vw", left: "85.3vw" },
  { top: "63.5vw", left: "80.9vw" },
  { top: "72vw", left: "74.3vw" },
  { top: "78.7vw", left: "65.7vw" },
  { top: "83.2vw", left: "55vw" },
  { top: "84.7vw", left: "44.5vw" },
  { top: "83.3vw", left: "33.2vw" },
  { top: "79vw", left: "23vw" },
  { top: "72vw", left: "14vw" },
  { top: "63vw", left: "7vw" },
  { top: "51.5vw", left: "2.5vw" },
  { top: "42.5vw", left: "1.6vw" },
  { top: "30vw", left: "3.5vw" },
  { top: "21vw", left: "7.2vw" },
  { top: "12vw", left: "14vw" },
  { top: "5.5vw", left: "22.5vw" },
  { top: "1vw", left: "33.5vw" },
];

export default function Wheel({
  spin,
  setPrize,
  prize,
  email,
  setResponseErrorMessage, result
}: IProps) {
  const [style, setStyle] = useState({});

  const [spinnerStart, setSpinnerStart] = useState(false);

  const [size] = useWindowSize();

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (spin) {
      setSpinnerStart(true);
      setFunctionCalls(functionCalls + 1);

	  const stopPoint = result ? 1 : 2;

      const deg = stopPoint * -30 + 15;

      setStyle({
        transition: "all ease 5s",
        transform: `rotate(${deg + 3600}deg)`,
      });

      setTimeout(async () => {
        setSpinnerStart(false);
        if (stopPoint < 2) {
          if (!Object.keys(prize).length) {
            setPrize("any");
            /*try {
              await axios.post(`${environment.baseUrl}benefit/deals`, {
                date: new Date(),
                email: email
              });
            } catch (e:any) {
              if (e.response && e.response.data && e.response.data.message) {
                setResponseErrorMessage(e.response.data.message);
              }
            }*/
          }
        } else {
          console.log("Comes");
          console.log(prize);
          if (!Object.keys(prize).length) {
            setPrize({
              title: "lose",
            });
          }
        }
      }, 5500);
    }
  }, [spin]);

  useEffect(() => {
    /*const wheelData: any = data;*/
    const todayDate = moment(new Date()).format("DD.MM.YYYY");
    const labelsArray: any = [];
    /*wheelData[todayDate].data.map((data: any) => {
      labelsArray.push(data.title);
    });*/
    setLabels(labelsArray);
  }, []);

  const [functionCalls, setFunctionCalls] = useState(0);

  const getStyle = (idx: number) => {
    if (spinnerStart) {
      if (idx % 2) {
        return "led-yellow two";
      } else {
        return "led-yellow";
      }
    } else if (Object.keys(prize).length) {
      return "led-yellow win";
    } else {
      return "led-yellow disabled";
    }
  };

  return (
    <div className="wheel" style={{ backgroundImage: `url('${spinBg}')` }}>
      <div
        className="arrow"
        style={{ backgroundImage: `url('${pinImage}')` }}
      />
      <div className="dot" style={{ backgroundImage: `url('${centerDot}')` }} />
      <div
        className="wheelShadow"
        style={{ backgroundImage: `url('${wheelShadow}')` }}
      />
      {/*<div className="podium" style={{ backgroundImage: `url('${podium}')` }} />*/}
      {/*<div
        className="podiumShadow"
        style={{ backgroundImage: `url('${podiumShadow}')` }}
      />*/}
      <div className="nestedCenter">
        <div />
      </div>

      {size < 901
        ? size < 501
          ? lightsPhone.map((light: any, idx: number) => (
            <div key={idx} className="led-box" style={light}>
              <div className={getStyle(idx)} />
            </div>
          ))
          : lightsPad.map((light: any, idx: number) => (
            <div className="led-box" key={idx} style={light}>
              <div className={getStyle(idx)} />
            </div>
          ))
        : lights.map((light: any, idx: number) => (
          <div className="led-box" key={idx} style={light}>
            <div className={getStyle(idx)} />
          </div>
        ))}

      <ul className="circle" style={style}>
        {Array.from(Array(12).keys()).map((num:number) => (
          <li key={num.toString()}>
            <div className="text">
              <p>{(num + 1) % 2
                ? "Gewinn"
                : "Kein Gewinn"
              }</p>
            </div>
          </li>
        ))}
      </ul>

    </div>
  );
}
